export default [
  {
    title: 'Dashboards',
    route: 'dashboard',
    icon: 'HomeIcon',
    action: 'browes',
    resource: 'dashboard',
  },
  {
    title: 'Roles',
    route: 'roles',
    icon: 'GlobeIcon',
    action: 'browes',
    resource: 'roles',
  },
  {
    title: 'Users',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Admin',
        route: 'users',
        action: 'browes',
        resource: 'admin_users',
      },
      {
        title: 'Merchant',
        route: 'merchant-users',
        action: 'browes',
        resource: 'merchant_users',
      },
      {
        title: 'Customer',
        route: 'customer-users',
        action: 'browes',
        resource: 'customer_users',
      },
    ],
  },
  {
    title: 'Shops',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'All Shops',
        route: 'merchants',
        action: 'browes',
        resource: 'merchants',
      },
      {
        title: 'Shops Types',
        route: 'merchant-types',
        action: 'browes',
        resource: 'merchant_types',
      },
      {
        title: 'Branches',
        route: 'branches',
        action: 'browes',
        resource: 'branches',
      },
    ],
  },
  {
    title: 'Offers',
    route: 'offers',
    icon: 'CreditCardIcon',
    action: 'browes',
    resource: 'offers',
  },
  {
    title: 'Vouchers',
    route: 'vouchers',
    icon: 'CreditCardIcon',
    action: 'browes',
    resource: 'offers',
  },
  {
    title: 'User Conatct',
    route: 'friends',
    icon: 'UsersIcon',
    action: 'browes',
    resource: 'friends',
  },
  {
    title: 'Places',
    icon: 'GlobeIcon',
    children: [
      {
        title: 'Countries',
        route: 'countries',
        action: 'browes',
        resource: 'countries',
      },
      {
        title: 'States',
        route: 'states',
        action: 'browes',
        resource: 'states',
      },
      {
        title: 'Cities',
        route: 'cities',
        action: 'browes',
        resource: 'cities',
      },
      {
        title: 'Areas',
        route: 'areas',
        action: 'browes',
        resource: 'areas',
      },
    ],
  },
  {
    title: 'Transaction',
    icon: 'GitPullRequestIcon',
    children: [
      {
        title: 'C-C',
        route: 'transactions-c-c',
        action: 'browes',
        resource: 'transactions',
      },
      {
        title: 'M-C',
        route: 'transactions-m-c',
        action: 'browes',
        resource: 'transactions',
      },
      {
        title: 'C-M',
        route: 'transactions-c-m',
        action: 'browes',
        resource: 'transactions',
      },
      {
        title: 'A-C',
        route: 'transactions-a-c',
        action: 'browes',
        resource: 'transactions',
      },
      {
        title: 'A-M',
        route: 'transactions-a-m',
        action: 'browes',
        resource: 'transactions',
      },
      {
        title: 'M-M',
        route: 'transactions-m-m',
        action: 'browes',
        resource: 'transactions',
      },
      {
        title: 'P-C',
        route: 'transactions-p-c',
        action: 'browes',
        resource: 'transactions',
      },
      {
        title: 'Send Money',
        route: 'transactions-send-money',
        action: 'send',
        resource: 'money',
      },
    ],
  },
  // {
  //   title: 'Configs',
  //   route: 'configs',
  //   icon: 'GridIcon',
  //   action: 'browes',
  //   resource: 'configs',
  // },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'General',
        route: 'settings',
        action: 'browes',
        resource: 'configs',
      },
      {
        title: 'Payment Types',
        route: 'providers',
        action: 'browes',
        resource: 'providers',
      },
      {
        title: 'Module Settings',
        route: 'moduel',
        action: 'browes',
        resource: 'configs',
      },
    ],
  },
  {
    title: 'Merchant Requests',
    route: 'merchant-requests',
    icon: 'GitPullRequestIcon',
    action: 'browes',
    resource: 'configs',
  },
  {
    title: 'Cashouts',
    route: 'cashouts',
    icon: 'DollarSignIcon',
    action: 'browes',
    resource: 'configs',
  },
  {
    title: 'Cashout Types',
    route: 'cashout-types',
    icon: 'DollarSignIcon',
    action: 'browes',
    resource: 'configs',
  },
  {
    title: 'Offer Categories',
    route: 'offer-categories',
    icon: 'DollarSignIcon',
    action: 'browes',
    resource: 'configs',
  },
  {
    title: 'Tickets',
    route: 'tickets',
    icon: 'HelpCircleIcon',
    action: 'browes',
    resource: 'cashouts',
  },
  {
    title: 'Ticket Categories',
    route: 'ticket-categories',
    icon: 'ListIcon',
    action: 'browes',
    resource: 'cashouts',
  },
  {
    title: 'Fees Profiles',
    route: 'fees-profiles',
    icon: 'DollarSignIcon',
    action: 'browes',
    resource: 'cashouts',
  },
  {
    title: 'Notifications',
    route: 'notifications',
    icon: 'BellIcon',
    action: 'browes',
    resource: 'cashouts',
  },
  {
    title: 'Services',
    route: 'services',
    icon: 'FileTextIcon',
    action: 'browes',
    resource: 'cashouts',
  },
  {
    title: 'Config Services',
    route: 'config-services',
    icon: 'SlidersIcon',
    action: 'browes',
    resource: 'cashouts',
  },
  {
    title: 'FAQs',
    route: 'faqs',
    icon: 'HelpCircleIcon',
    action: 'browes',
    resource: 'cashouts',
  },
  {
    title: 'Financial Reports',
    route: 'financial-reports',
    icon: 'LayersIcon',
    action: 'browes',
    resource: 'cashouts',
  },
  {
    title: 'Damen Service',
    icon: 'ServerIcon',
    children: [
      {
        title: 'Category Aggregator',
        route: 'damen-categories',
        action: 'browes',
        resource: 'configs',
      },
      {
        title: 'Biller Aggregator',
        route: 'damen-billers',
        action: 'browes',
        resource: 'providers',
      },
      {
        title: 'Service Aggregator',
        route: 'damen-services',
        action: 'browes',
        resource: 'configs',
      },
      {
        title: 'Pending Transactions',
        route: 'pending-transactions',
        action: 'browes',
        resource: 'configs',
      },
    ],
  },

  // {
  //   title: 'Notifications',
  //   route: 'notifications',
  //   icon: 'BellIcon',
  //   action: 'browse',
  //   resource: 'notifications',
  // },
]
